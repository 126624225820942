




































import {
  defineComponent,
  computed,
  ComputedRef,
  useStore,
} from '@nuxtjs/composition-api'
import { SimpleLink } from '@/contracts/SimpleLink'

export default defineComponent({
  name: 'BlockBreadCrumbs',
  setup(_props, ctx) {
    const list: ComputedRef<SimpleLink> = computed(
      () => ctx.root.$store.getters['breadcrumbs/GET_BREADCRUMBS']
    )
    const isVisible: ComputedRef<SimpleLink> = computed(
      () => ctx.root.$store.getters['breadcrumbs/GET_BREADCRUMBS_VISIBILITY']
    )
    const { getters } = useStore()
    const hideHeader = computed(() => getters['settings/getHideHeader'])
    const hideFooter = computed(() => getters['settings/getHideFooter'])

    return {
      isVisible,
      list,
      hideHeader,
      hideFooter,
    }
  },
})
