var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",class:{
    'hero-about': _vm.isHeroAbout,
    'is-large': _vm.isLarge,
    'is-small': _vm.isHeroAbout && !_vm.isLarge,
  }},[_c('f-col',{staticClass:"column-left",attrs:{"sm":12,"lg":_vm.compLgCol}},[((_vm.isHeroAbout ? _vm.buttonActions : true) && _vm.needBreadCrumbs)?_c('block-bread-crumbs',{staticClass:"crumbs"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-wrapper"},[_c('h1',{staticClass:"title word-breaking",class:_vm.classObject,domProps:{"innerHTML":_vm._s(_vm.getColorTitle(_vm.title))}}),_vm._v(" "),(_vm.additionalText)?_c('p',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.additionalText),expression:"additionalText"}],staticClass:"last-modified text--second"}):_vm._e(),_vm._v(" "),(_vm.text)?_c('div',{directives:[{name:"html-with-scripts",rawName:"v-html-with-scripts",value:(_vm.text),expression:"text"}],staticClass:"text text--basic-second word-breaking"}):_vm._e()]),_vm._v(" "),(_vm.showVideoBtn)?_c('BlockVideoPopup',{attrs:{"open":_vm.toggleVideoPopup,"video-link":_vm.videoLink},on:{"close":_vm.onVideoBlockClose}}):_vm._e(),_vm._v(" "),(!_vm.isHeroAbout)?_c('f-col',{staticClass:"image-container",class:{ 'mob-hidden': _vm.isMobImageHidden },attrs:{"lg":_vm.imageCol}},[_c('div',{class:{
          'image-column': _vm.image,
          rounded: _vm.roundImage,
          'video-column': _vm.videoLink && !_vm.image,
        }},[(_vm.showVideoBtn)?_c('button',{staticClass:"video-btn-mobile",on:{"click":_vm.onVideoBtnClick}}):_vm._e(),_vm._v(" "),(_vm.videoLink && !_vm.image)?_c('iframe',{attrs:{"id":"hero-video-iframe","src":_vm.videoLink,"height":"100%","width":"100%","frameborder":"0","scrolling":"no","autofocus":"","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_c('f-picture',{attrs:{"source-default":_vm.image,"is-initial-size":false,"is-lazy":false,"is-cover":true,"sizes":_vm.sizes}}),_vm._v(" "),(_vm.chipText)?_c('div',{staticClass:"image-chip-text",domProps:{"innerHTML":_vm._s(_vm.chipText)}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),_c('intersect',{on:{"enter":_vm.onEnter,"leave":_vm.onLeave}},[(_vm.buttonActions)?_c('block-buttons',{class:{ 'no-margin': _vm.isHeroAbout },attrs:{"button-actions":_vm.buttonActions}}):_vm._e()],1)],1),_vm._v(" "),(_vm.showVideoBtn)?_c('button',{staticClass:"video-btn",on:{"click":_vm.onVideoBtnClick}}):_vm._e(),_vm._v(" "),(_vm.showButton && _vm.formView && _vm.clickShowButton && _vm.needBtnSide)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('button',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.needBtnSide.label),expression:"needBtnSide.label"}],staticClass:"vacancy-btn-leave btn--basic",attrs:{"type":"button"},on:{"click":function($event){return _vm.onClick(_vm.needBtnSide)}}})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }