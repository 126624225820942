import { SectionData } from './SectionData'

export interface IPromoCode {
  code: string
  name: string
  text: string
  textNoActive: string
  startDatePromo: string
  endDatePromo: string
  activePromo: string
}

export interface IPromoBanner extends IPromoCode {
  isActive: boolean
}

export interface IPlugData {
  plugDateOn: string
  plugDateOff: string
  plugTitle: string
  plugText: string
}

export interface ISectionIframe {
  sectionData: SectionData
  iframeData: string
  plugData: IPlugData
  promoCodes: Array<IPromoCode> | []
  clearPage: boolean
}

export const PULSE_DOMAIN = 'pulse'

export const emptyPlugData = (): IPlugData => {
  return {
    plugDateOn: '',
    plugDateOff: '',
    plugTitle: '',
    plugText: '',
  }
}

export const emptyPromoCode = (): IPromoCode[] => {
  return [
    {
      code: '',
      name: '',
      text: '',
      textNoActive: '',
      startDatePromo: '',
      endDatePromo: '',
      activePromo: '',
    },
  ]
}
