export type ButtonAction = {
  id: string
  name: string
  label: string
  'id-label'?: string
  color: string
  type:
    | 'link'
    | 'switchLink'
    | 'scrollToContacts'
    | 'scrollToBuyByTelForm'
    | 'scrollToAgentsForm'
    | 'scrollToCallbackRegion'
    | 'openModal'
  url?: string
  'id-url'?: string
  eventAction?: string
  eventCategory?: string
  padding: boolean
  arrow: boolean
  background?: string
  backgroundHover?: string
  borderColor?: string
  labelColor?: string
  labelHoverColor?: string
  borderRadius?: string
  modalBackground?: string
  modalRadius?: string
  modalBorder?: string
  modalText?: string
  jsCode?: string
}

export enum ButtonHeroActionType {
  NotSet = 'NotSet',
  BuyOnline = 'BuyOnline',
  RequestCall = 'RequestCall',
}
