






































































































import {
  defineComponent,
  computed,
  ref,
  onBeforeUnmount,
  useStore,
  onMounted,
  useRoute,
  PropType,
} from '@nuxtjs/composition-api'
import { throttle } from 'throttle-debounce'
import Intersect from 'vue-intersect'
import queryString from 'query-string'
import BlockBreadCrumbs from '@/components/BlockBreadCrumbs.vue'
import { getColorTitle } from '@/composition/coloredTitle'
import { PULSE_DOMAIN } from '@/contracts/ISectionIframe'
import BlockButtons from '@/components/BlockButtons.vue'
import { buttonsAction } from '@/composition/buttons'
import {
  ButtonAction,
  ButtonHeroActionType,
} from '@/contracts/ButtonHeroAction'

export default defineComponent({
  name: 'BlockProductHero',
  components: {
    BlockButtons,
    BlockBreadCrumbs,
    BlockVideoPopup: () => import('@/components/BlockVideoPopup.vue'),
    Intersect,
  },
  props: {
    roundImage: {
      type: Boolean,
      default: false,
    },
    isHeroAbout: {
      type: Boolean,
      default: false,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
    needBreadCrumbs: {
      type: Boolean,
      default: false,
    },
    isMobImageHidden: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    additionalText: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    videoLink: {
      type: String,
      default: '',
    },
    imageCol: {
      type: Number,
      default: 7,
    },
    chipText: {
      type: String,
      default: '',
    },
    buttonActions: {
      type: Array as PropType<ButtonAction[]>,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const { clickHandler } = buttonsAction()
    const newButtonActions = ref([])
    const needBtnSide = ref<ButtonAction>()
    const timeoutId = ref()
    if (props.buttonActions) {
      newButtonActions.value = props.buttonActions.map((item) => {
        if (item.type === 'scrollToAgentsForm') needBtnSide.value = item
        return item
      })
    }
    const toggleVideoPopup = ref(false)
    const showButton = ref(false)
    const formView = ref(true)
    const clickShowButton = ref(true)
    const buttonActionType = ButtonHeroActionType
    const compLgCol = computed(() => {
      let colLg
      if (props.isHeroAbout && props.isLarge) {
        colLg = 7
      } else if (!props.isHeroAbout && !props.isLarge) {
        colLg = 12 - props.imageCol
      } else {
        colLg = 9
      }
      return colLg
    })

    const onClick = (button: ButtonAction) => {
      clickShowButton.value = false
      const { type, eventAction, eventCategory, jsCode } = button || {}
      clickHandler(type, eventAction, eventCategory, jsCode)
      setTimeout(() => {
        clickShowButton.value = true
      }, 2000)
    }
    const sizes = {
      sm: [343, 200],
      md: [762, 360],
      lg: [762, 360],
    }

    const onScroll = throttle(50, () => {
      if (process.browser && needBtnSide.value) {
        if (!document.querySelector('.agents')) return
        const position = document
          .querySelector('.agents')
          .getBoundingClientRect()
        formView.value =
          position.top < window.innerHeight && position.bottom >= 0
      }
    })
    onBeforeUnmount(() => {
      clearTimeout(timeoutId.value)
      if (process.browser) {
        window.removeEventListener('scroll', onScroll)
      }
    })

    onMounted(() => {
      console.log(props.text)
      timeoutId.value = setTimeout(() => {
        const query = queryString.stringify(route.value.query)
        const links = document.querySelectorAll('a')
        links.forEach((link: HTMLAnchorElement) => {
          if (link.href.includes(PULSE_DOMAIN)) {
            const splitUrl = link.href?.split('#')

            link.href = `${splitUrl[0] || ''}${
              query && !link.href.includes(query)
                ? link.href.includes('?')
                  ? '&'
                  : '?'
                : ''
            }${link.href && !link.href.includes(query) ? query : ''}${
              splitUrl[1] ? `#${splitUrl[1]}` : ''
            }`
          }
        })
      }, 0)
      if (process.browser && needBtnSide.value && props.buttonActions) {
        window.addEventListener('scroll', onScroll)
      }
    })

    const onEnter = () => {
      showButton.value = false
    }

    const onLeave = () => {
      showButton.value = true
    }

    const showVideoBtn = computed(
      () => !props.isHeroAbout && props.videoLink && props.image
    )

    const onVideoBtnClick = () => {
      toggleVideoPopup.value = true
      store.commit('settings/SET_HIDE_HEADER', true)
    }

    const onVideoBlockClose = () => {
      toggleVideoPopup.value = false
      store.commit('settings/SET_HIDE_HEADER', false)
    }

    const classObject = computed(() => {
      return [
        (props.isHeroAbout && !props.isLarge) || props.isHeroAbout
          ? 'title--h1'
          : 'title--h2',
        props.isHeroAbout
          ? props.buttonActions && props.isHeroAbout
            ? 'about-title-btn'
            : 'no-margin'
          : '',
      ]
    })

    return {
      onVideoBtnClick,
      onVideoBlockClose,
      showVideoBtn,
      toggleVideoPopup,
      classObject,
      needBtnSide,
      showButton,
      formView,
      clickShowButton,
      onEnter,
      onLeave,
      buttonActionType,
      compLgCol,
      getColorTitle,
      onClick,
      sizes,
      newButtonActions,
    }
  },
})
