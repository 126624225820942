




































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { sectionCoreProps } from '@/mixins/sectionCore'
import { ButtonAction } from '@/contracts/ButtonHeroAction'
import BlockProductHero from '@/components/BlockProductHero.vue'

export default defineComponent({
  name: 'SectionProductHero',
  components: {
    BlockProductHero,
  },
  mixins: [sectionCoreProps],
  props: {
    alt: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    isHeroAbout: {
      type: Boolean,
      default: false,
    },
    isMobImageHidden: {
      type: Boolean,
      default: false,
    },
    additionalText: {
      type: String,
      default: '',
    },
    roundImage: {
      type: Boolean,
      default: false,
    },
    imageDesktop: {
      type: String,
      default: '',
    },
    imageMobile: {
      type: String,
      default: '',
    },
    videoLink: {
      type: String,
      default: '',
    },
    imageCol: {
      type: Number,
      default: 7,
    },
    buttonActions: {
      type: Array as PropType<ButtonAction[]>,
      default: () => [],
    },
    chipText: {
      type: String,
      default: '',
    },
  },
  setup() {
    const sizes = {
      sm: [375, 443],
      md: [1024, 322],
      lg: [2520, 792],
    }

    return {
      sizes,
    }
  },
})
