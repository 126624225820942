// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/svg/icon/arrow_up_big.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*! mixins */.link-mob[data-v-2d4e585c]{display:none}@media (max-width:767px){.link-mob[data-v-2d4e585c]{display:block}.link-mob[data-v-2d4e585c]:active{color:var(--primary-active)}.link-mob[data-v-2d4e585c]:before{content:\"\";background-color:currentColor;transform:rotate(90deg);-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center/100% no-repeat;mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center/100% no-repeat;margin-right:.8rem;height:1.4rem;width:1.4rem;display:inline-block;vertical-align:bottom}}.block-bread-crumbs[data-v-2d4e585c]{display:flex;flex-wrap:wrap;grid-gap:.4rem;gap:.4rem;width:100%}.border[data-v-2d4e585c]{height:100%;width:1px;background:var(--text-primary)}.bread-crumbs-item[data-v-2d4e585c]{overflow:hidden;text-overflow:ellipsis;position:relative;white-space:nowrap;display:flex}.bread-crumbs-item[data-v-2d4e585c]:not(:last-child){flex-shrink:0}@media (min-width:1024px){.bread-crumbs-item:not(:last-child) a.link[data-v-2d4e585c]{transition:all .25s ease}.bread-crumbs-item:not(:last-child) a.link[data-v-2d4e585c]:hover{cursor:pointer;color:var(--primary)}.bread-crumbs-item:not(:last-child) a.link[data-v-2d4e585c]:active{color:var(--primary-active)}}.bread-crumbs-item .border[data-v-2d4e585c]{margin:0 .7rem}.bread-crumbs-item:nth-last-child(-n+2) .border[data-v-2d4e585c]:not(.non-active){background:var(--primary)}.bread-crumbs-item[data-v-2d4e585c]:last-child{margin-right:0;color:var(--primary)}.bread-crumbs-item:last-child .border[data-v-2d4e585c]{display:none}.bread-crumbs-item[data-v-2d4e585c]:first-child{padding-left:0}@media (max-width:767px){.bread-crumbs-item[data-v-2d4e585c]{display:none}}@media (min-width:1024px){a.link[data-v-2d4e585c]{transition:all .25s ease}a.link[data-v-2d4e585c]:hover{cursor:pointer}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
